module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Travelguider","short_name":"Travelguider","start_url":"/","background_color":"#027256","theme_color":"#f6be09","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84c0ba7a19dfac15fa9a8e5203ffc041"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["et","en"],"defaultLanguage":"et","siteUrl":"https://www.travelguider.ee/","generateDefaultLanguagePage":true,"redirect":true,"i18nextOptions":{"setHtmlLanguageAttribute":true,"prefixDefault":false,"interpolation":{"escapeValue":false},"nsSeparator":false,"generateDefaultLanguagePage":false,"redirect":false},"pages":[{"matchPath":"/:lang?/","getLanguageFromPath":true},{"matchPath":"/:lang?/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/elamus/:name/:id","getLanguageFromPath":true},{"matchPath":"/:lang?/experience/:name/:id","getLanguageFromPath":true},{"matchPath":"/:lang?/elamus/eestipurchase/:name/:id","getLanguageFromPath":true},{"matchPath":"/:lang?/experience/purchase/:name/:id","getLanguageFromPath":true},{"matchPath":"/:lang?/lood/:author/:story","getLanguageFromPath":true},{"matchPath":"/:lang?/stories/:author/:story","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
