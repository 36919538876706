import './src/styles/global.scss';
import './src/styles/fonts.css';

// const loadTrengoOnScroll = () => {
// 	if (typeof window !== 'undefined' && !window.Trengo) {
// 		window.Trengo = window.Trengo || {};
// 		window.Trengo.key = 'W2dsQCsmU0aBZyQzqVZK';

// 		(function (d) {
// 			const script = d.createElement('script');
// 			script.type = 'text/javascript';
// 			script.async = true;
// 			script.src = 'https://static.widget.trengo.eu/embed.js';
// 			d.getElementsByTagName('head')[0].appendChild(script);
// 		})(document);
// 	}
// };

// const handleUserInteraction = () => {
// 	if (typeof window !== 'undefined') {
// 		if (!window.Trengo) {
// 			loadTrengoOnScroll();
// 		}
// 		window.removeEventListener('scroll', handleUserInteraction);
// 		window.removeEventListener('click', handleUserInteraction);
// 		window.removeEventListener('mousemove', handleUserInteraction);
// 		window.removeEventListener('keydown', handleUserInteraction);
// 	}
// };

export const onRouteUpdate = () => {
	// if (typeof window !== 'undefined' && !window.Trengo) {
	// 	window.addEventListener('scroll', handleUserInteraction);
	// 	window.addEventListener('click', handleUserInteraction);
	// 	window.addEventListener('mousemove', handleUserInteraction);
	// 	window.addEventListener('keydown', handleUserInteraction);
	// }

	if (typeof window !== 'undefined') {
		window.scrollTo(0, 0);
	}
};
